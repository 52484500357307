import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Project } from '../../../features/projects/interfaces/project.interface';
import { GlobalStoreService } from '../../../global/services/global-store.service';

@Component({
  selector: 'app-filtered-projects',
  templateUrl: './filtered-projects.component.html',
  styleUrl: './filtered-projects.component.scss',
})
export class FilteredProjectsComponent implements OnInit {
  @Input() expertise!: string | undefined;
  @Input() selectedProjectId: number | null | undefined = null;
  protected readonly environment = environment;
  public isListOfColumnEmpty = false;
  public columns: string[] = [
    'firstColumn',
    'secondColumn',
    'thirdColumn',
    'fourthColumn',
  ];
  public listOfColumnItems: any = {
    firstColumn: [],
    secondColumn: [],
    thirdColumn: [],
    fourthColumn: [],
  };
  public warningMessage = 'No projects are present!';
  public dataSource: any = [];

  constructor(
    private globalStore: GlobalStoreService,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.globalStore.selectAllProjects().subscribe((projects: Project[]) => {
      this.dataSource = projects;
      this.restListOfColumnItems();
      this.initFilter();
    });
  }

  private restListOfColumnItems(): void {
    this.listOfColumnItems['firstColumn'] = [];
    this.listOfColumnItems['secondColumn'] = [];
    this.listOfColumnItems['thirdColumn'] = [];
    this.listOfColumnItems['fourthColumn'] = [];
  }

  private populateArticles(projects: Project[]): void {
    const numColumns = 4;
    const totalArticles = projects.length;
    const articlesPerColumn = Math.floor(totalArticles / numColumns);
    const extraArticles = totalArticles % numColumns;

    let column = 0;
    let articlesAdded = 0;

    projects.forEach((project) => {
      if (
        articlesAdded >=
        articlesPerColumn + (column < extraArticles ? 1 : 0)
      ) {
        column++;
        articlesAdded = 0;
      }
      switch (column) {
        case 0:
          this.listOfColumnItems.firstColumn.push(project);
          break;
        case 1:
          this.listOfColumnItems.secondColumn.push(project);
          break;
        case 2:
          this.listOfColumnItems.thirdColumn.push(project);
          break;
        case 3:
          this.listOfColumnItems.fourthColumn.push(project);
          break;
      }
      articlesAdded++;
    });
    this.isListOfColumnEmpty =
      !this.listOfColumnItems['firstColumn'].length &&
      !this.listOfColumnItems['secondColumn'].length &&
      !this.listOfColumnItems['thirdColumn'].length &&
      !this.listOfColumnItems['fourthColumn'].length;
  }

  public initFilterByExpertiseSelected(): void {
    this.initFilter();
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  public initFilter(): void {
    let filteredData = this.dataSource;

    if (this.selectedProjectId) {
      filteredData = this.dataSource.filter(
        (project: any) => project.id !== this.selectedProjectId
      );
    }

    const expertisesFilterValue = this.expertise;

    const filteredDataSource = filteredData.filter((project: Project) => {
      return expertisesFilterValue?.includes(project.expertise);
    });

    this.restListOfColumnItems();
    this.populateArticles(filteredDataSource);
  }
}
