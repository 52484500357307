import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LottieComponent } from 'ngx-lottie';
import { NavSideComponent } from './components/admin-layouts/components/nav-side/nav-side.component';
import { NavTopComponent } from './components/admin-layouts/components/nav-top/nav-top.component';
import { FilteredProjectsComponent } from './components/filtered-projects/filtered-projects.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavHeaderComponent } from './components/layouts/nav-header/nav-header.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { MaterialModule } from './material/material.module';
import { TranslatePipe } from './pipes/translate.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    NavHeaderComponent,
    FooterComponent,
    WarningMessageComponent,
    TruncatePipe,
    FilteredProjectsComponent,
    NavSideComponent,
    NavTopComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ReactiveFormsModule,
    LottieComponent,
  ],
  exports: [
    MaterialModule,
    TranslatePipe,
    NavHeaderComponent,
    FooterComponent,
    CommonModule,
    ReactiveFormsModule,
    WarningMessageComponent,
    TruncatePipe,
    FilteredProjectsComponent,
    NavSideComponent,
    NavTopComponent,
    LottieComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
