export const navSideData = [
  {
    routerLink: ['', 'projects'],
    icon: 'fal fa-regular fa-folder-open',
    label: 'admin.projects',
  },
  {
    routerLink: ['', 'news'],
    icon: 'fal fa-regular fa-newspaper',
    label: 'admin.news',
  },
  {
    routerLink: ['', 'expertises'],
    icon: 'fal fas fa-tools',
    label: 'admin.services',
  },
  {
    routerLink: ['', 'careers'],
    icon: 'fal fa-solid fa-briefcase',
    label: 'admin.careers',
  },
  {
    routerLink: ['', '/admin'],
    icon: 'fal fa-user',
    label: 'admin.profile',
  },
];
