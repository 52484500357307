<div class="h-auto bg-metal pt-8 w-[100%]">
  <div
    class="flex lg:justify-center md:justify-center justify-center flex-wrap w-[80%] m-auto">
    <div class="w-[50%] min-w-[350px] p-4">
      <img
        class="lg:w-[350px] md:w-[300px] sm:w-[300px] w-[300px] cursor-pointer img-logo"
        src="assets/img/logo-1.svg"
        routerLink="/" />
      <p
        class="lg:w-[350px] md:w-[300px] sm:w-[300px] w-[300px] h-[35px] flex justify-center items-center mt-8">
        <i class="fab fa-linkedin" style="color: #ffffff"></i>
        <i class="fab fa-instagram" style="color: #ffffff"></i>
        <i class="fab fa-twitter-square" style="color: #ffffff"></i>
        <i class="fab fa-youtube" style="color: #ffffff"></i>
      </p>
    </div>
    <div
      class="flex flex-wrap gap-12 md:w-[50%] sm:w-[100%] w-[100%] min-w-[260px] p-4">
      <div>
        <h1
          class="text-[#CDD315FF] text-[20px] font-black cursor-pointer"
          routerLink="/expertises">
          Expertises.
        </h1>
        <p
          *ngFor="let expertise of expertiseList"
          class="py-1 text-[#F5F5F5FF] font-black cursor-pointer"
          [routerLink]="
            '/expertises/expertise/' + expertise.id + '/' + expertise.name
          ">
          {{ expertise.name }}
        </p>
      </div>
      <div>
        <h1 class="text-[#CDD315FF] text-[20px] font-black">Legal.</h1>
        @for (legal of legalList; track legal) {
          <a [routerLink]="legal.link">
            <p class="py-1 text-[#F5F5F5FF] font-black cursor-pointer">
              {{ legal.label }}
            </p>
          </a>
        }
      </div>
    </div>
  </div>
  <div
    class="flex flex-wrap gap-2 lg:justify-between md:justify-between sm:justify-center justify-center w-[100%] px-14 pt-4 text-[#aaaaaa]">
    <div class="w-auto flex items-center text-center">
      <p>© {{ date }} Azone Construction - All rights reserved</p>
    </div>
    <a href="https://duku.al/" target="_blank">
      <div
        class="logo-container w-auto flex flex-wrap items-center justify-center">
        <img
          class="img-logo cursor-pointer w-[33px]"
          src="assets/img/duku-logo.svg" />
        <label class="text-[#ffffff] ml-1 cursor-pointer">DUKU Agency</label>
      </div>
    </a>
  </div>
</div>
