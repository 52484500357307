import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GlobalStoreService } from '../../../../global/services/global-store.service';
import { Expertise } from '../../../interfaces/shared-interface';
import { LEGAL_LIST } from '../nav-data';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit, OnDestroy {
  public date = new Date().getFullYear();
  public expertiseList: Expertise[] = [];
  public legalList = LEGAL_LIST;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private globalStore: GlobalStoreService) {}

  ngOnInit(): void {
    this.globalStore.fetchAllExpertises();
    this.globalStore
      .selectAllExpertises()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((expertises: Expertise[]) => {
        this.expertiseList = expertises;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
