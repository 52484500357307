import { Link } from '../../interfaces/shared-interface';

export const NAV_SIDE_DATA: Link[] = [
  {
    routerLink: ['', 'about-us'],
    label: 'app.about',
  },
  {
    routerLink: ['', 'expertises'],
    label: 'app.services',
  },
  {
    routerLink: ['', 'projects'],
    label: 'app.projects',
  },
  {
    routerLink: ['', 'news'],
    label: 'app.news',
  },
  {
    routerLink: ['', 'careers'],
    label: 'app.careers',
  },
  {
    routerLink: ['', 'contact'],
    label: 'app.contact',
  },
];

export const LEGAL_LIST = [
  {
    label: 'Terms and Conditions',
    link: 'terms-conditions',
  },
  {
    label: 'Privacy Policy',
    link: 'privacy-policy',
  },
];

export const TITLES = [
  'Architecture',
  'Structural Engineering',
  'Civil Engineering',
  'Scaffolding',
];

export const EXPERTISE_LIST = [
  'Architecture',
  'Structural Engineering Solution',
  'Civil Engineering Solution',
  'General contracting',
  'Residential & Commercial',
  'Refurbishment',
  'Ground Works',
  'Scaffolding',
];
